new WOW({ offset: 70 }).init();

function loadSliders() {

	$('.clients-gallery__slides').slick({
		centerPadding: 0,
		slidesToShow: 3,
		arrows: true,
		prevArrow: $('.clients-gallery__arrow--left'),
		nextArrow: $('.clients-gallery__arrow--right'),
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	if ($("#HomepageMarker").length !== 0) {
		$(".category-links__link").addClass("wow fadeIn");
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo img").addClass("wow flipInX").attr("data-wow-delay", "0.5s");
		} else {
			$(".header__logo img").css("visibility", "visible");
		}

	} else {
		$(".header__logo img").css("visibility", "visible");
		$('.left-block').addClass('wow zoomIn');
	}

	$('.SearchPanel input').on('focus', function () {
		$(this).css('z-index', 2);
	});

	$('.SearchPanel input').on('blur', function () {
		$(this).css('z-index', 1);
	});

});

